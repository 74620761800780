import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'button-cy',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
  ]
})
export class ButtonCapYeiComponent {

  @Input() addClass?:string;
  @Input() label!: string;
  @Input() icon?:string;
  @Input() iconOnly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() type: string = 'button';
  @Output() onPress = new EventEmitter<any>();


  constructor() {}

  action() {
    this.onPress.emit();
  }
}
