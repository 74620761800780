
import { Injectable } from '@angular/core';

import Swal, { SweetAlertIcon } from 'sweetalert2';

export class Button {
  show?: boolean;
  text?: string;
  color?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor() {}

  alertCapYei({
    type,
    message,
    buttons = {
      cancel: {
        show: true,
        text: 'Cancelar',
        color: '#ff6862',
      },
      confirm: {
        show: true,
        text: 'Aceptar',
        color: 'var(--primary-color)',
      },
    },
    description,
    close = true,
  }: {
    type: SweetAlertIcon,
    message: string,
    buttons?: { cancel?: Button, confirm?: Button, deny?: Button },
    description?: any,
    close?:boolean,
  }) {
    // console.log(message, type, buttons, description);
    let title = 'Error';
    let text = message || 'Error';

    if(this.lengthItems(description)) {
      title = message;
      if(this.lengthItems(text)) {
        text = text ? text : text + '<hr>' + this.textFormat(description);
      } else {
        text = this.textFormat(description);
      }
    } else {
      if(message) {
        title = 'Error';
        text = message;
      }
      if(description != null) {
        title = message || 'Error';
        text = description;
      }
    }
    setTimeout(() => {
      Swal.fire({
        title: title,
        html: text,
        icon: type,

        showCancelButton: buttons?.cancel?.show ?? true,
        cancelButtonText: buttons?.cancel?.text ?? 'Cancelar',
        cancelButtonColor: buttons?.cancel?.color ?? '#ff6862',

        showConfirmButton: buttons?.confirm?.show ?? true,
        confirmButtonText: buttons?.confirm?.text ?? 'Aceptar',
        confirmButtonColor: buttons?.confirm?.color ?? 'var(--primary-color)',

        showDenyButton: buttons?.deny?.show ?? false,
        denyButtonText: buttons?.deny?.text ?? 'Rechazar',
        denyButtonColor: buttons?.deny?.color ?? '#222',

        allowOutsideClick: close,
        allowEscapeKey: close,
      });
    }, 1);
  }
  
  private textFormat(description: any) {
    let data = '';
    for(let item of description) {
      data = data.concat(`<p>${item}</p>`);
    }
    return data;
  }

  private lengthItems(items: any) {
    if(Array.isArray(items)) {
      if(items.length >= 1) {
        return true;
      } else {
        return false;
      }
  } else {
      return false;
    }
  }
}
