
 import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from './notification.service';
import { AlertService } from './alert.service';
import { Router } from '@angular/router';
import { LoadingService } from './loading.service';
import { AbstractControl, ValidatorFn } from '@angular/forms';

export interface Data {
    title: string;
    url: string;
    external: boolean;
    active: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class XtrasService {

    private emitChangeSource: BehaviorSubject<Data[]> = new BehaviorSubject<Data[]>([{title: 'Mi cuenta', url: '/account', external: false, active: true }]);

    get sharingObservable() {
        return this.emitChangeSource.asObservable();
    }

    set sharingObservableData(data: Data[]) {
        this.emitChangeSource.next([...data]);
    }

    constructor(
        private load: LoadingService,
        private toast: NotificationService,
        private alert: AlertService,
        private router: Router,
    ) {}

    lengthItems(items: any) {
        if(Array.isArray(items)) {
            if(items.length >= 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    formatDate(date?: any) {
        const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agostos', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ];
        if(!date) return '-';
        const dateFormat = new Date(date);
        return `${monthNames[dateFormat.getMonth()]} ${dateFormat.getDate()}, ${dateFormat.getFullYear()}`;
    }

    formatDateTime(date: any) {
        if(date) {
            const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agostos', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ];

            const dateFormat = new Date(date);
            return `${dateFormat.getDate()}/${('0' + (dateFormat.getMonth()+1)).slice(-2)}/${dateFormat.getFullYear()} - ${('0' + dateFormat.getHours()).slice(-2)}:${('0' + dateFormat.getMinutes()).slice(-2)}:${('0' + dateFormat.getSeconds()).slice(-2)}`;
        } else {
            return '-';
        }
    }

    generateMinToken() {
        var token = '';
        var str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' + 'abcdefghijklmnopqrstuvwxyz0123456789@#$';
        for (let i = 1; i <= 8; i++) {
            var char = Math.floor(Math.random() * str.length + 1);
            token += str.charAt(char)
        }

        return token;
    }

    removeObjectWithVar(array: any, vr: any, filtVr: any, vr2: any, filtVr2: any) {
        console.log(vr);
        console.log(filtVr);
        const objWithIdIndex = array.findIndex((obj: any) => {
            obj[vr] == filtVr;
            obj[vr2] == filtVr2;
        });
        array.splice(objWithIdIndex, 1);

        return array;
    }

    numericOnly(event: any): boolean {
        let pattern = /^([0-9])$/;
        let result = pattern.test(event.key);
        return result;
    }

    getFullName(name: string, lastName?: string, motherLastName?: string) {
        return `${name} ${lastName ?? ''} ${motherLastName ?? ''}`
    }

    getAction(action: string): string {
        switch (action) {
            case 'edit':
                return 'Editar';
        
            case 'read':
                return 'Ver';

            case 'filter':
                return 'Filtros';
            
            default:
                return 'Crear';
        }
    }

    async getFormData(data: { [key: string]: any }): Promise<FormData> {
        let formData = new FormData();

        await Promise.all(Object.entries(data).map( ([key, val]:any) => {
            if (val !== null && val !== undefined ) {
                //console.log(key, val)

                if(Array.isArray(val)){
                    //console.log('Es un array', key);
                    val.forEach((item) => {
                        //console.log(key, item)
                      formData.append('key', item);
                    });

                }else{
                    //console.log('Noooo es array')
                    formData.append(key, val);
                }

            } 
        }));
      
        return formData;
    }

    getErrorApi(result: any, loading:boolean = false, redirectError: string = '/', errorDefault?:string) {
        if(loading) {
            this.load.dismiss();
        }
  
        if(result['redirect']) {
          this.router.navigateByUrl(redirectError);
        }
        if(result['message']) {
            if(result['errors']) {
                this.alert.alertCapYei({
                    type: 'error',
                    message: result['message'],
                    buttons: {
                        cancel: { show: false },
                        confirm: { show: true, text: 'Entendido' },
                    },
                    description: result['errors']
                });
            } else {
                this.alert.alertCapYei({
                    type: 'error',
                    message: result['message'],
                    buttons: {
                        cancel: { show: false },
                        confirm: { show: true, text: 'Entendido' },
                    },
                    description: 'No se pudo obtener la información, intente nuevamente.'
                });
            }
        } else {
            if(result['errors']) {
                this.alert.alertCapYei({
                    type: 'error',
                    message: '¡Error!',
                    buttons: {
                        cancel: { show: false },
                        confirm: { show: true, text: 'Entendido' },
                    },
                    description: result['errors']
                });
            } else {
                this.toast.error('¡Error!', errorDefault ?? 'No se pudo obtener la información, intente nuevamente.', 4000);
            }
        }
    }

    getIDDateTime() {
		var today = new Date();
		var y = today.getFullYear();
		// JavaScript months are 0-based.
		var m = today.getMonth() + 1;
		var d = today.getDate();
		var h = today.getHours();
		var mi = today.getMinutes();
		var s = today.getSeconds();
		return y + '_' + m + '_' + d + '_' + h + '_' + mi + '_' + s;
	}

    downloadFile(file: any, fileName: string, typeFile: string) {

        //*Hasta que habiliten coors
        const link = document.createElement('a');
        
        link.href = file;
        link.download = fileName;
        link.target = '_blank';
      
        link.style.display = 'none';
        document.body.appendChild(link);
      
        link.click();
      
        setTimeout(() => {
            document.body.removeChild(link);
        }, 100);

        // let blobData;
        // let name = fileName.replace(/\//g, '-');
        // if (file.startsWith('http') || file.startsWith('https')) {
        //     // Si es una URL, se hace una petición para obtener el Blob
        //     fetch(file, {
        //         // headers: {
        //         //     'Content-Type': 'application/json',
        //         //     'Access-Control-Allow-Origin': '*'
        //         // },

        //         // mode: 'no-cors'
        //     })
        //     .then(response => response.blob())
        //     .then((blob): any => {
        //         if(blob instanceof Blob) {
        //             this.downloadBlob(blob, name);
        //         } else if(typeof blob === 'string' && this.isBase64(blob)) {
        //             blobData = this.base64ToBlob(blob, typeFile);
        //             this.downloadBlob(blobData, name);
        //         } else {
        //             console.error('response.data no es un Blob ni base64');
        //             alert('El archivo a descargar no tiene un formator válido');
        //             return null;
        //         }
        //     })
        //     .catch(error => console.error(error));
        // } else {
        //     // Si es una cadena base64, se convierte a Blob
        //     blobData = this.base64ToBlob(file, typeFile);
        //     if(blobData)
        //     this.downloadBlob(blobData, name);
        // }
    }

    isBase64 = (str: string) => {
        try {
            atob(str);
            return true;
        } catch {
            return false;
        }
    }

    base64ToBlob(base64: string, typeFile: string) {
        let _base64 = base64;
        if(base64.includes('data:')) {
            _base64 = base64.substring(base64.indexOf(',') + 1);
        }
        const byteCharacters = atob(_base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: typeFile });
    }

    downloadBlob(blob: Blob, fileName: string) {
        const urlBlob = URL.createObjectURL(blob);
        const link = document.createElement('a');
      
        link.href = urlBlob;
        link.download = fileName;
      
        // Esto es necesario para que la descarga funcione en Firefox
        link.style.display = 'none';
        document.body.appendChild(link);
      
        link.click();
      
        // Se revoca la URL del Blob y se elimina el enlace del DOM
        setTimeout(() => {
            URL.revokeObjectURL(urlBlob);
            document.body.removeChild(link);
        }, 100);
    }

    checkPasswords: ValidatorFn = (group: AbstractControl): {[key: string]: any} | null => {
        const password = group.get('password');
        const confirm_password = group.get('confirm_password');
        return password && confirm_password && password.value === confirm_password.value ? null : { notSame: true };
    }
}
